import { isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled, { keyframes } from 'styled-components';
import { useShop } from '../../contexts/shop';
import AddProductButton from '../Utility/AddProductButton';
import Loading from '../Utility/Loading';
import Rendered from '../Utility/Rendered';
import { MEAL_PLAN_DEFAULT_SELECT, KEYWORD_UNIQUE_ROUTINE } from '../../helpers/constants';
import ScrollableTabsContainer from './ScrollableTabsContainer'

const Wrapper = styled.div``;
const ImageWrapper = styled.div``;
const Image = styled.img``;
const InfoWrapper = styled.section``;
const Info = styled.section``;
const Title = styled.h2``;
const Text = styled.p``;
const Button = styled(AddProductButton)``;
const Article = styled.article``;
const Description = styled.p``;
const Dropdown = styled.select`
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  box-shadow: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  border: 0;
  padding: 0;
`;

const VariationButtonScroll = styled.button`
  border: 1px solid #dbdbdb;
  border-color: ${({ isActive }) => (isActive ? '#574cff !important' : '#dadce0')};
  background-color: ${({ isActive }) => (isActive ? '#574cff !important' : '#FFF')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#202124')};
  border-style: solid;
  outline: none;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 15px;
  padding: 12px 15px;
  letter-spacing: -0.5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding-left: 45px;
  position: relative;
  transition: 0.3s ease all;
`;
const VariationButton = styled.button`
  border: 1px solid #dbdbdb;
  border-color: ${({ isActive }) => (isActive ? '#574cff' : '#dbdbdb')};
  background-color: ${({ isActive }) => (isActive ? '#574cff' : '#FFF')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#000')};
  border-style: solid;
  outline: none;
  border-radius: 0px;
  width: calc(33.33% - 10px);
  box-sizing: border-box;
  font-size: 1rem;
  padding: 1rem 0;
  letter-spacing: -0.5px;
  cursor: pointer;
  margin: 10px 0;
  transition: 0.3s ease all;
  :hover {
    color: #fff;
    background-color: #574cff;
  }
  :nth-child(3n-1) {
    margin: 10px 15px;
  }
`;
const slideDown = keyframes`
  from {
    top: -30px;
  }

  to {
    top: 0px;
  }
`;

const Callout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcd34d;
  color: #fff;
  padding: 10px;
  font-family: 'Circular-Std-Book';
  text-transform: uppercase;
  font-weight: 100;
  font-size: 12px;
  animation: ${slideDown} 0.3s;
`;

const Variations = ({
  options,
  variation,
  onChange,
  secondaryOptions,
  secondaryVariation,
  onSecondaryChange,
}) => {
  const parsed = queryString.parse(window.location.search);

  return (
    <>
      {parsed.callout === 'true' && (
        <Callout>👇 Elige que tipo de plan quieres!</Callout>
      )}
      <Text style={{ marginBottom: 8 }}> </Text>
      <Dropdown
        onChange={({ target: { value } }) => onSecondaryChange(value)}
        value={secondaryVariation}
      >
        {secondaryOptions.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Dropdown>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {options.map((option) => (
          <VariationButton
            key={option}
            type="button"
            onClick={() => onChange(option)}
            isActive={variation === option}
          >
            {`${option} cal`}
          </VariationButton>
        ))}
      </div>
    </>
  );
};

const VariationsScroll = ({
  options,
  variation,
  onChange,
  secondaryOptions,
  secondaryVariation,
  onSecondaryChange,
}) => {
  const parsed = queryString.parse(window.location.search);
  return (
    <>
      {parsed.callout === 'true' && (
        <Callout>👇 Elige que tipo de plan quieres!</Callout>
      )}
      <Text style={{ marginBottom: 8 }}></Text>
      <ScrollableTabsContainer secondaryVariation={secondaryVariation} secondaryOptions={secondaryOptions} onSecondaryChange={onSecondaryChange}/> 
      <br />
      {variation === KEYWORD_UNIQUE_ROUTINE ? (
          null
        ) : (
          <>
            {options.length > 0 ? (
               <div className="caSplit"><Text>Selecciona una opción</Text></div>
            ) : null }
            <>
              <div className="wkList">
                {options.map((option) => (
                  <VariationButtonScroll
                    key={option}
                    type="button"
                    onClick={() => onChange(option)}
                    isActive={variation === option}
                  >
                    {`${option}`}
                  </VariationButtonScroll>
                ))}
              </div>
              <br />
            </>
          </>
        )}
    </>
  );
};

const ProductSingle = () => {
  const { productId } = useParams();
  const { shopItems, shopItemsDietaryPlans } = useShop();
  const [variation, setVariation] = useState(0);
  const [secondaryVariation, setSecondaryVariation] = useState('');
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [mainRoutine, setMainRoutine] = useState({});
  const IS_MEAL_PLAN = productId.includes('0');
  const [secondaryOptions, setSecondaryOptions] = useState([]);
  const VALUE_DEFAULT_PLAN = 'Selecciona tu tipo de plan';
  // ['Omnívoro', 'Vegetariana', 'Vegana'];

  const groupTypePlan = () => {
    if (shopItemsDietaryPlans && shopItemsDietaryPlans.length === 0) return [];
    const arrayPlan = shopItemsDietaryPlans.map(({ type }) => type);
    const resgroupTypePlan = [VALUE_DEFAULT_PLAN].concat(
      arrayPlan.filter((element, i) => i === arrayPlan.indexOf(element)),
    );
    return resgroupTypePlan;
  };
  const groupFocus = (grouped_focuses) => {
    if (grouped_focuses && grouped_focuses.length === 0) return [];
    const arrayPlan = grouped_focuses.map(({ focus }) => focus);
    const resgroupFocus = arrayPlan.filter((element, i) => i === arrayPlan.indexOf(element))
    return resgroupFocus;
  };
  const groupWorkout = (grouped_focuses, focus) => {
    if (grouped_focuses && grouped_focuses.length === 0) return [];
    
    const groupedWorkout = {};
    grouped_focuses.forEach((routine) => {
      const focus = routine.focus;
      if (!groupedWorkout[focus]) {
          groupedWorkout[focus] = [];
      }
      groupedWorkout[focus].push(routine.workout);
    });    
    console.log(groupedWorkout[focus]);
    return groupedWorkout[focus];
  };

  const groupcalories = (plan) => {
    if (shopItemsDietaryPlans && shopItemsDietaryPlans.length === 0) return [];
    const arrayCalories = shopItemsDietaryPlans.filter((item) =>
      item.type.toLowerCase().includes(plan.toLowerCase()),
    );
    const resGroupCalories = arrayCalories.map(({ calorie }) => calorie);
    resGroupCalories.sort();
    return resGroupCalories;
  };
  // get plan description
  const getPlanDdescription = () => {    
    if (IS_MEAL_PLAN && variation === 0) return;

    if((mainRoutine && mainRoutine.main) && secondaryVariation !== VALUE_DEFAULT_PLAN) {
      const plan = mainRoutine.grouped_focuses.find(({ focus, workout }) => 
      variation === workout &&
      focus.toLowerCase().includes(secondaryVariation.toLowerCase()),
      );
      if(plan) {
        const { id, price, media, RoutineTranslations: [{ title, description }] } = plan.routine;
        setData({
          id,
          price,
          media,
          title,
          description,
          main: false
        });
      }
      return;
    }
    

      const plan = IS_MEAL_PLAN
      ? shopItemsDietaryPlans.find(
          ({ calorie, type }) =>
            variation === calorie &&
            type.toLowerCase().includes(secondaryVariation.toLowerCase()),
        )
      : shopItems.find(({ id }) => id.toString() === productId);

      if (isNil(plan)) return <Loading />;
        
      const { id, price, media, title, description, main, grouped_focuses } = plan;
      setData({
        id,
        price,
        media,
        title,
        description,
        main,
        grouped_focuses
      });
    
  };
  useEffect(() => {
    const routine = IS_MEAL_PLAN
      ? {}
      : shopItems.find(({ id }) => id.toString() === productId);
    setMainRoutine(routine);
  }, [shopItems]);
  useEffect(() => {
    const typesPlan = IS_MEAL_PLAN ? groupTypePlan() : ((mainRoutine && mainRoutine.main) ? groupFocus(mainRoutine.grouped_focuses) : []);
    if (typesPlan && typesPlan.length === 0) return;
    setSecondaryOptions(typesPlan);
    setSecondaryVariation((mainRoutine && mainRoutine.main) ? VALUE_DEFAULT_PLAN : typesPlan[0]);
  }, [shopItemsDietaryPlans, mainRoutine]);

  useEffect(() => {
    if ((IS_MEAL_PLAN || mainRoutine.main) && secondaryVariation === VALUE_DEFAULT_PLAN) {
      setOptions([]);
      setData((mainRoutine && mainRoutine.main) ? mainRoutine : MEAL_PLAN_DEFAULT_SELECT);
      return;
    }
    if (!secondaryVariation) return;
    const calories = IS_MEAL_PLAN ? groupcalories(secondaryVariation) : ((mainRoutine && mainRoutine.main) ? groupWorkout(mainRoutine.grouped_focuses, secondaryVariation) : []);

    if (calories && calories.length === 0) return;
    setOptions(calories);
    setVariation(calories[0]);
    getPlanDdescription();
  }, [secondaryVariation, shopItemsDietaryPlans]);
  useEffect(() => {
    getPlanDdescription();
  }, [variation, shopItemsDietaryPlans]);

  if (isEmpty(shopItems) || isEmpty(shopItemsDietaryPlans)) return <Loading />;
  return (
    <>
      <Wrapper className="caFullLayout caClearfix">
        <ImageWrapper className="caLeftSide caClearfix">
          <Image alt="product" src={data.media} />
        </ImageWrapper>
        <InfoWrapper className="caRightSide caClearfix">
          <Info>
            <Title>{IS_MEAL_PLAN ? 'Plan Alimenticio' : data.title}</Title>
            <div className="caSplit">
              <Text>
                {(!IS_MEAL_PLAN || secondaryVariation !== VALUE_DEFAULT_PLAN)
                  ? `$${data.price} MX`
                  : ''}
              </Text>
            </div>

            {IS_MEAL_PLAN ? (
              <div className="caBoxUns">
                <div
                  dangerouslySetInnerHTML={{
                    __html: MEAL_PLAN_DEFAULT_SELECT.description,
                  }}
                ></div>{' '}
              </div>
            ) : null}
          <div className="caPlanFeatures">
              {IS_MEAL_PLAN && (
                <Variations
                  options={options}
                  variation={variation}
                  onChange={setVariation}
                  secondaryVariation={secondaryVariation}
                  secondaryOptions={secondaryOptions}
                  onSecondaryChange={setSecondaryVariation}
                />
              )}
              {mainRoutine.main && (
                <VariationsScroll
                  options={options}
                  variation={variation}
                  onChange={setVariation}
                  secondaryVariation={secondaryVariation}
                  secondaryOptions={secondaryOptions}
                  onSecondaryChange={setSecondaryVariation}
                />
              )}
              
            </div>
          </Info>

          {!IS_MEAL_PLAN || secondaryVariation !== VALUE_DEFAULT_PLAN ? (
            // <Article className="caProductDetails">
            //   <Description>
                <Rendered html={data.description} />
            //   </Description>
            // </Article>
          ) : null}

          {(!IS_MEAL_PLAN || secondaryVariation !== VALUE_DEFAULT_PLAN) && !data.main ? (
            <div className={!mainRoutine.main ? "caBoxUns" : "caUns"}>
            {!mainRoutine.main ? (
              <>
              <h2>¿Cómo comprar este programa?</h2>
              <ul>
                <li>
                  <span className="caSps">
                    ¡Compra tus programas por medio de esta página web para
                    tener el mejor precio! Los pasos son los siguientes:
                  </span>
                </li>
                <li>
                  <b>Paso 1: </b>
                  <span>
                    Realiza la compra de tu programa en esta página iniciando
                    sesión con tu correo. (Durante el proceso de compra podrás
                    hacerlo, dando click en “Agregar al carrito”).
                  </span>
                </li>
                <li>
                  <b>Paso 2: </b>
                  <span>Descarga la app MadyFit en tu iPhone o Android.</span>
                </li>
                <li>
                  <b>Paso 3: </b>
                  <span>
                    Inicia sesión dentro de la app MadyFit con el mismo correo
                    con el que adquiriste tu programa.
                  </span>
                </li>
                <li>
                  <span className="caSpx">
                    ¡Listo! En cuanto se procese tu compra verás dentro de la
                    app MadyFit tus programas activos. Si tienes algún problema
                    con el proceso puedes mandar correo a soporte@madyfit.com.
                  </span>
                </li>
              </ul>
              </>
              ) : null}

              <Button
                title={data.title}
                variation={
                  IS_MEAL_PLAN
                    ? { mealType: secondaryVariation, calories: variation }
                    : null
                }
                id={data.id}
                enabled={false}
                mainRoutine={mainRoutine}
              >
                Agregar al Carrito
              </Button>
            </div>
          ) : null}
        </InfoWrapper>
      </Wrapper>
    </>
  );
};

export default ProductSingle;
